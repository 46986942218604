<template>
    <div class="p-grid p-jc-center no_margin" >
        <div class="p-col-9 p-md-9 p-lg-9 p-grid p-nogutter">
            <div class="p-col-12 p-grid p-field text-left">
                <h1 style="width:100%;">{{$t("createDepartmentView.title")}}</h1>
                <span style="width:100%;font-size: 12px;" >{{$t("createDepartmentView.note")}}</span>
                <span style="width:100%;font-size: 12px;" >{{$t("createDepartmentView.note2")}}</span>
            </div>
            <div class="p-col-12 p-grid p-field">
                <label class="p-col-fixed" style="width:220px" for="fieldId">{{$t("createDepartmentView.organizationName")}}</label>
                <Dropdown class="p-col text-left" v-model="CompanyInfo" :options="CompanyList" optionLabel="Name" :emptyMessage="$t('inviteUserView.companyEmptyMessage')" />
            </div>
            <div class="p-col-12 p-grid p-field">
                <label class="p-col-fixed" style="width:220px" for="departmentId">{{$t("createDepartmentView.departmentName")}}</label>
                <InputText class="p-col" style="padding: 0.5rem 0.5rem;" v-model="DepartmentName" id="departmentId" type="text" :placeholder="$t('createDepartmentView.newDepartmentNameInputPlaceholder')" />
            </div>
            <div class="p-col-12 p-grid">
                <div class="p-col-8  p-grid p-field">
                    <label class="p-col-fixed" style="width: 220px;" for="departmentCode">{{$t("createDepartmentView.departmentCode")}} ({{$t("createDepartmentView.optional")}})</label>
                    <InputText class="p-col-fixed" style="width: 180px;padding: 0.5rem 0.5rem;" v-model="DepartmentCode" id="departmentCode" type="text"  />
                </div>
                <div class="p-col  p-grid p-field " style="justify-content: flex-end;">
                    <Button  class=" p-button-outlined p-button-info" @click="addDepartment" v-bind:disabled="isProcessing()" >{{$t("createDepartmentView.register")}}</Button>
                </div>
            </div>
            <div class="p-col-12 p-mt-4 p-grid p-field text-right">
                <span style="width:100%;font-size: 12px;" >{{$t("createDepartmentView.doneCreatingDepartmentInfo")}}</span>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import http from '../mixins/api'
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import Processing from '@/mixins/processing'



export default {
    name: 'CreateDepartment',
    mixins: [Processing],
    components: {
        InputText,
        Dropdown,
        Button,
    },
    data: function() {
        return {
            OrganizationName: null,
            DepartmentName: null,
            DepartmentCode: "",
            CompanyInfo: null,
            Email: null,
            Name: null,
            // CompanyList: null,
            filteredCompanyList: null,
            filteredDepartmentList: null,
            EmailList: [],
            EmailAddValue: "",
            invalid: false,
        }
    },

    created: async function() {
        await this.$store.dispatch("userBranchInfo/setMemberList")
    },

    beforeRouteEnter(route, redirect, next) {
        const query = route.query
        const CompanyId = query.c
        next(vm => vm.setData({CompanyId}))

    },

    computed: {
        CompanyList: {
            get: function() {
                return this.$store.getters["userBranchInfo/getCompanyList"]
            },
        },
    },

    methods: {
        setData(params) {
            const CompanyId = params.CompanyId
            if(CompanyId) {
                const companyList = this.$store.getters["userBranchInfo/getCompanyList"]
                const company = companyList.find(x => CompanyId == x._id)
                if(company) {
                    this.CompanyInfo = company
                }
            }
        },

        async addDepartment() {
            this.startProcessing()
            const CompanyInfo = this.CompanyInfo;
            const DepartmentName = this.DepartmentName
            const DepartmentCode = this.DepartmentCode
            if(CompanyInfo && DepartmentName) {
                if(DepartmentName.length > this.$constants.maxTextLength) {
                    this.$toast.add({severity:'error', detail: this.$i18n.tc("utils.invalidMaxTextLength"), life: this.$constants.warningHelperLife, group: this.$constants.defaultToastGroup});
                } else {
                    const res = await http.post("/user/AddDepartment", {CompanyInfo: CompanyInfo, DepartmentName: DepartmentName, DepartmentCode: DepartmentCode})
                    this.CompanyInfo = null
                    this.DepartmentName = null
                    this.DepartmentCode = null
                    if(res.Success) {
                        this.$toast.add({severity:'success', summary: this.$i18n.tc("invited.registedDepartment"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                    } else {
                        this.$toast.add({severity:'error', summary: res.Message ? res.Message : "error", life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                    }
                }
            }
            this.endProcessing()
        },
        async inviteUser() {
            const valid = this.isValidEmailList()
            this.startProcessing()
            if(valid) {
                const CompanyList = this.CompanyList
                const DepartmentList = this.DepartmentList
                const EmailList = this.EmailList
                const Email = this.Email
                const Name = this.Name
                if(CompanyList && DepartmentList && Email) {
                    if(Name && Name.length > this.$constants.maxTextLength) {
                        this.$toast.add({severity:'error', detail: this.$i18n.tc("utils.invalidMaxTextLength"), life: this.$constants.warningHelperLife, group: this.$constants.defaultToastGroup});
                    } else {
                        const res = await http.post("/user/AddUserInDepartment", {CompanyId: CompanyList._id, DepartmentId: DepartmentList._id, Email: Email, Name: Name})
                        if(res.Success) {
                            this.$toast.add({severity:'success', summary: this.$i18n.tc("invited.registedSuccess"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                            this.CompanyList = null
                            this.DepartmentList = null
                            this.EmailList = null
                        } else {
                            this.$toast.add({severity:'error', detail:this.$i18n.tc("invited.registedFailed"), life: this.$constants.warningHelperLife, group: this.$constants.defaultToastGroup});
                        }
                    }
                }
            }
            this.endProcessing()
        },

        onEmailListAddBlur(e) {
            const value = e.target.value
            if(value) {
                this.EmailList.push(value)
            }
            e.target.value = ""
            this.isValidEmailList()
        },

        isValidEmailList(){ 
            const Email = this.Email
            const regexp = new RegExp("^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$")
            return /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(Email)
        }
    }
}
</script>

